function SuggestionsSkeleton() {
  return (
    <div className="flex animate-pulse border-r pr-[3rem] lg:min-w-[38rem] lg:max-w-[48rem]">
      <div className="mt-[1rem] flex flex-col lg:ml-[9rem]">
        <p className="mb-1 block h-4 w-32 rounded bg-gray-400"></p>
        <p className="mb-1 mt-2 block h-4 w-64 rounded bg-gray-400"></p>
        <div className="flex gap-[0.8rem] lg:flex-col">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              className="my-[0.7rem] flex flex-row items-center rounded-md border-2 px-[1.2rem] py-[0.7rem]"
              key={index}
            >
              <div className="mr-[1rem] h-4 flex-grow rounded bg-gray-400"></div>
              <div className="h-4 w-4 rounded bg-gray-400"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SuggestionsSkeleton;
