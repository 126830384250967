function ProductHitSkeleton() {
  return (
    <div className="m-2 animate-pulse rounded border p-4 shadow-lg">
      <div className="flex items-center">
        <div className="mr-5 h-10 w-10 rounded-md bg-gray-400"></div>
        <div className="flex flex-col">
          <div className="mb-2 h-4 w-32 rounded bg-gray-400"></div>
          <div className="mb-2 h-4 w-20 rounded bg-gray-400"></div>
          <div className="h-4 w-16 rounded bg-gray-400"></div>
        </div>
      </div>
    </div>
  );
}

export default ProductHitSkeleton;
