function SellerHitSkeleton() {
  return (
    <div className="m-2 animate-pulse rounded border p-4 shadow-lg">
      <div className="flex items-center gap-[1.6rem]">
        <div className="h-12 w-12 rounded-full bg-gray-400"></div>
        <div className="flex flex-col">
          <div className="mb-2 h-4 w-24 rounded bg-gray-400"></div>
          <div className="flex flex-row items-center gap-[0.8rem]">
            <div className="flex items-center text-lg font-semibold">
              <div className="mr-2 flex h-4 w-4 items-center rounded bg-gray-400"></div>
              <div className="h-4 w-8 rounded bg-gray-400"></div>
            </div>
            <div className="ml-2 mr-2 h-4 w-4 rounded bg-gray-400"></div>
            <div className="h-4 w-24 rounded bg-gray-400"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerHitSkeleton;
