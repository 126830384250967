import ProductHitSkeleton from './ProductHitSkeleton';
import SuggestionsSkeleton from './SuggestionsSkeleton';
import SellerHitSkeleton from './SellerHitSkeleton';

function SearchResultsSkeleton() {
  return (
    <div className="flex max-h-[70rem] flex-col overflow-hidden p-4 lg:max-h-none lg:min-h-screen lg:flex-row lg:overflow-auto">
      <SuggestionsSkeleton />
      <div className="mr-[6rem] flex grow flex-col gap-[1.6rem] lg:ml-[2rem] lg:gap-[3.2rem]">
        <div className="m-[1rem] flex animate-pulse flex-row gap-[0.6rem]">
          <span className="block h-4 w-20 rounded bg-gray-400"></span>
          <span className="h-4 w-20 rounded bg-gray-400"></span>
        </div>
        <div className="flex flex-col gap-[1.6rem] lg:flex-row">
          {/* Repeat the skeleton loader for the number of expected products */}
          {Array.from({ length: 5 }).map((_, index) => (
            <ProductHitSkeleton key={index} />
          ))}
        </div>
        <div className="m-[1rem] flex animate-pulse flex-row gap-[0.6rem]">
          <span className="block h-4 w-20 rounded bg-gray-400"></span>
          <span className="h-4 w-20 rounded bg-gray-400"></span>
        </div>
        <div className="flex gap-[1.6rem]">
          {/* Repeat the skeleton loader for the number of expected sellers */}
          {Array.from({ length: 5 }).map((_, index) => (
            <SellerHitSkeleton key={index} />
          ))}
        </div>
        <div className="flex w-full animate-pulse items-center justify-center p-4 font-semibold sm:hidden">
          <span className="block h-8 w-48 rounded bg-gray-400"></span>
        </div>
      </div>
    </div>
  );
}

export default SearchResultsSkeleton;
