'use client';
import SearchResultsPreview from '@c/SearchResultsPreview';
import { searchClient } from '@util/getTypesense';
import { isMobile } from '@util/index';
import { useSearch } from 'context/SearchContext';
import { ReactNode } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { SearchClient } from 'typesense-instantsearch-adapter';

// NOTE: may want to keep an eye on this and perhaps use display: none instead of returning another component
interface MainContentProps {
  children: ReactNode;
}
const MainContent = ({ children }: MainContentProps) => {
  const { showSearchPreview } = useSearch();
  let content = (
    <div className="flex min-h-screen w-full flex-col items-center">
      {children}
    </div>
  );

  if (showSearchPreview) {
    content = (
      <div className="min-h-screen w-full">
        <InstantSearch searchClient={searchClient as SearchClient}>
          <Configure
            // @ts-ignore FIXME: not sure why hitsPerPage throws type error... see https://discourse.algolia.com/t/property-hitsperpage-does-not-exist-for-configure-hitsperpage/18231
            hitsPerPage={isMobile() ? 3 : 5}
          />
          <SearchResultsPreview />
        </InstantSearch>
      </div>
    );
  }

  return content;
};

export default MainContent;
